import React from "react";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  return (
    <footer>
      <div className="footer--container wrapper">
        <Link to="/" className="logo">
          <img src=".\assets\images\Lookscope-footer-logo.png" alt="" />
        </Link>
        <div className="resources">
          <p>Resources</p>
          <div className="resources__links">
            <Link to="/inspirations">Inspiration</Link>
            <a href="https://www.lookscopeapp.com/best-practices.html">
              Best Practices
            </a>
            <a href="https://www.lookscopeapp.com/how-tos.html">How To's</a>
          </div>
        </div>
        <div className="other">
          <p>Other</p>
          <div className="resources__links">
            <a href="https://medium.com/lookscope/how-lookscope-was-born-dd531f3d1d1d">
              About
            </a>
            <a href="mailto:hi@lookscopeapp.com" target="_top">
              Contact
            </a>
            <a href="https://www.lookscopeapp.com/terms.html">Terms</a>
            <a href="https://www.lookscopeapp.com/privacy.html">Privacy</a>
            <a href="https://www.lookscopeapp.com/v2beta.html">V2 Beta Test</a>
          </div>
        </div>
        <div className="social">
          <div className="social__container">
            <a href="https://www.instagram.com/lookscope/?hl=en">
              <img src="./assets/images/instagram-logo.png" alt="" />
            </a>
            <a href="https://www.facebook.com/lookscope">
              <img src="./assets/images/facebook-logo.png" alt="" />
            </a>
            <a href="https://medium.com/lookscope">
              <img src="./assets/images/medium-logo.png" alt="" />
            </a>
            <a href="https://itunes.apple.com/app/apple-store/id1214741326?mt=8">
              <img src="./assets/images/app-store-logo.png" alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className="copyright">
        <p>© 2019 Lookscope, Inc.</p>
        <p>
          Credits: Design by
          <a href="/"> Jennifer Lee</a>, development by{" "}
          <a href="https://www.ansonli.io"> Anson Li</a>, branding help by{" "}
          <a href="https://songsuho.com"> Suho Song</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
