import axios from "axios";

export function fetchBrands() {
  return function(dispatch: any) {
    dispatch({ type: "FETCH_BRANDS_PENDING", payload: null });
    axios({
      method: "POST",
      params: {
        ckAPIToken:
          "e77360a702ff8b523c437b5346f3eb4f7424667a1cddea61dedb57dbbd2ab42d"
      },
      baseURL:
        "https://api.apple-cloudkit.com/database/1/iCloud.com.lookscopeapp.lookscope/development/public/records/query",
      data: {
        zoneID: {
          zoneName: "_defaultZone"
        },
        query: {
          recordType: "Brand"
        }
      }
    })
      .then(response => {
        dispatch({ type: "FETCH_BRANDS_FULFILLED", payload: response.data });
      })
      .catch(err => {
        dispatch({ type: "FETCH_BRANDS_REJECTED", payload: err });
      });
  };
}

export function fetchOutfitExamples(continuationMarker?: string) {
  return function(dispatch: any) {
    if (continuationMarker) {
      dispatch({ type: "FETCH_CONTINUED_EXAMPLES_PENDING", payload: null });
    } else {
      dispatch({ type: "FETCH_EXAMPLES_PENDING", payload: null });
    }
    axios({
      method: "POST",
      params: {
        ckAPIToken:
          "e77360a702ff8b523c437b5346f3eb4f7424667a1cddea61dedb57dbbd2ab42d"
      },
      baseURL:
        "https://api.apple-cloudkit.com/database/1/iCloud.com.lookscopeapp.lookscope/development/public/records/query",
      data: {
        zoneID: {
          zoneName: "_defaultZone"
        },
        query: {
          recordType: "OutfitExampleSmall",
          sortBy: [
            {
              fieldName: "name",
              ascending: false
            }
          ]
        },
        resultsLimit: 12,
        continuationMarker
      }
    })
      .then(response => {
        if (continuationMarker) {
          dispatch({
            type: "FETCH_CONTINUED_EXAMPLES_FULFILLED",
            payload: response.data
          });
        } else {
          dispatch({
            type: "FETCH_EXAMPLES_FULFILLED",
            payload: response.data
          });
        }
      })
      .catch(err => {
        dispatch({ type: "FETCH_EXAMPLES_REJECTED", payload: err });
      });
  };
}

export function fetchFilteredOutfits(filter: any, continuationMarker?: string) {
  return function(dispatch: any) {
    if (continuationMarker) {
      dispatch({
        type: "FETCH_CONTINUED_FILTERED_EXAMPLES_PENDING",
        payload: null
      });
    } else {
      dispatch({ type: "FETCH_FILTERED_EXAMPLES_PENDING", payload: null });
    }
    axios({
      method: "POST",
      params: {
        ckAPIToken:
          "e77360a702ff8b523c437b5346f3eb4f7424667a1cddea61dedb57dbbd2ab42d"
      },
      baseURL:
        "https://api.apple-cloudkit.com/database/1/iCloud.com.lookscopeapp.lookscope/development/public/records/query",
      data: {
        zoneID: {
          zoneName: "_defaultZone"
        },
        query: {
          recordType: "OutfitExampleSmall",
          filterBy: filter
        },
        resultsLimit: 12,
        continuationMarker
      }
    })
      .then(response => {
        if (continuationMarker) {
          dispatch({
            type: "FETCH_CONTINUED_FILTERED_EXAMPLES_FULFILLED",
            payload: response.data
          });
        } else {
          dispatch({
            type: "FETCH_FILTERED_EXAMPLES_FULFILLED",
            payload: response.data
          });
        }
      })
      .catch(err => {
        dispatch({ type: "FETCH_FILTERED_EXAMPLES_REJECTED", payload: err });
      });
  };
}

export function fetchOutfitDetails(recordName: string) {
  return function(dispatch: any) {
    dispatch({ type: "FETCH_OUTFIT_DETAILS_PENDING", payload: null });
    axios({
      method: "POST",
      params: {
        ckAPIToken:
          "e77360a702ff8b523c437b5346f3eb4f7424667a1cddea61dedb57dbbd2ab42d"
      },
      baseURL:
        "https://api.apple-cloudkit.com/database/1/iCloud.com.lookscopeapp.lookscope/development/public/records/query",
      data: {
        zoneID: {
          zoneName: "_defaultZone"
        },
        query: {
          recordType: "OutfitExampleLarge",
          filterBy: [
            {
              comparator: "EQUALS",
              fieldName: "name",
              fieldValue: {
                value: recordName
              }
            }
          ]
        }
      }
    })
      .then(response => {
        dispatch({
          type: "FETCH_OUTFIT_DETAILS_FULFILLED",
          payload: response.data
        });
      })
      .catch(err => {
        dispatch({ type: "FETCH_OUTFIT_DETAILS_REJECTED", payload: err });
      });
  };
}
