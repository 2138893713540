import React from "react";

import { filterSelected } from "../../Actions/filterActions";

interface filtersProps {
  filters: string[];
  filterTitle: string;
  filterType: string;
  dispatch: any;
}
const Filter: React.FC<filtersProps> = props => {
  // dispatch an action to update the filters selected/deselected
  const onClick = (e: any) => {
    const box = e.currentTarget;

    // ensure that the checkboxes work as intended, as well as paired up with the "checked" classes to display the Xs
    if (box.classList.contains("checked")) {
      box.classList.remove("checked");
      box.checked = false;
    } else {
      box.classList.add("checked");
      box.checked = true;
    }

    // if radio button, then allow it to be deselected
    if (box.type === "radio") {
      const boxId = box.id,
        parentList = box.parentElement.parentElement.children,
        siblingList = [];

      // logic to remove the "checked" class on the other boxes

      // get list of siblings in the filter group
      for (let el of parentList) {
        if (el.classList.contains("filterItem")) {
          siblingList.push(el.children.item(0));
        }
      }

      // remove the checked class from all other filter items in the same group
      for (let i = 0; i < siblingList.length; i++) {
        if (
          siblingList[i].classList.contains("checked") &&
          siblingList[i].id !== boxId
        ) {
          siblingList[i].classList.remove("checked");
          props.dispatch(filterSelected(props.filterTitle, siblingList[i]));
        }
      }
    }
    props.dispatch(filterSelected(props.filterTitle, e.currentTarget));
  };

  return (
    <ul>
      <div className="filterTitle" aria-label={props.filterTitle + " Filters"}>
        By {props.filterTitle}:
      </div>
      {props.filters.map((name, index) => {
        return (
          <li className="filterItem" key={"filter_" + index} aria-label={name}>
            <input
              type={
                props.filterType !== "color" ? props.filterType : "checkbox"
              }
              id={"filter__" + name}
              name={props.filterTitle}
              onClick={onClick}
              className="filter__checkbox"
            />
            <label
              className={
                "filter__option" +
                (props.filterType === "color"
                  ? " " + name.replace(/\s/g, "").toLowerCase()
                  : "")
              }
              htmlFor={"filter__" + name}
              title={name}
            >
              {name}
            </label>
            <div className="filter__x">
              <span className="forwardSlash"></span>
              <span className="backslash"></span>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default Filter;
