export const genderFilter = ["Male", "Female"];
export const seasonsFilter = ["Spring", "Summer", "Fall", "Winter"];
export const styleFilter = ["Active", "Casual", "Smart-Casual", "Formal"];
export const patternFilter = [
  "Stripe",
  "Check",
  "Plaid",
  "Polka-Dot",
  "Camo",
  "Quilt",
  "Camouflage",
  "Other"
];
export const colorFilter = [
  "White",
  "Light Gray",
  "Gray",
  "Black",
  "Light Brown",
  "Brown",
  "Dark Brown",
  "Grayish Brown",
  "Light Yellow",
  "Yellow",
  "Dark Yellow",
  "Grayish Yellow",
  "Light Orange",
  "Orange",
  "Dark Orange",
  "Grayish Orange",
  "Light Red",
  "Red",
  "Dark Red",
  "Grayish Red",
  "Light Green",
  "Green",
  "Dark Green",
  "Grayish Green",
  "Light Blue",
  "Blue",
  "Dark Blue",
  "Grayish Blue",
  "Light Purple",
  "Purple",
  "Dark Purple",
  "Grayish Purple",
  "Bright Green",
  "Bright Blue",
  "Bright Pink",
  "Bright Purple"
];
export const itemFilter = [
  "Jacket",
  "Coat",
  "Sweater",
  "Shirt",
  "Chinos",
  "Jeans",
  "Dressy Pants",
  "Sneakers",
  "Boots",
  "Dressy Shoes"
];
