import React from "react";

interface categoryProps {
  category: string;
  text: string;
}

const OutfitCategory: React.FC<categoryProps> = props => {
  return (
    <div className="details__view--category">
      <h5>{props.category}:</h5>
      <p>{props.text}</p>
      {/* <a href="#">Find Similar Items</a> */}
    </div>
  );
};

export default OutfitCategory;
