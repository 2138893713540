export function filterSelected(title: string, target: any) {
  const filterName = target.id.toLowerCase().split("__")[1];
  return {
    type:
      title.toUpperCase() +
      "_FILTER_" +
      (target.checked ? "" : "DE") +
      "SELECTED",
    payload: {
      filterName
    }
  };
}
