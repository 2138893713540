import React from "react";
import "./App.scss";
import { Provider } from "react-redux";
import store from "./store";
// import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import { HashRouter as Router, Route } from "react-router-dom";

import Header from "./Components/Header";
import Home from "./Components/Home";
import Footer from "./Components/Footer";
import Inspirations from "./Components/Inspirations/Inspirations";
import Outfits from "./Components/Outfits/Outfits";

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <div className="App">
        <Router>
          <Header />
          <Route exact path="/" component={Home} />
          <Route path="/inspirations" component={Inspirations} />
          <Route path="/outfits/:recordName" component={Outfits} />
          <Footer />
        </Router>
      </div>
    </Provider>
  );
};

export default App;
