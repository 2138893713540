const fetchReducer = (
  state = {
    fetching: false,
    fetched: false,
    payload: [],
    details: [],
    continuationMarker: "",
    filteredSearch: false,
    newSearch: false
  },
  action: any
) => {
  switch (action.type) {
    case "FETCH_BRANDS_FULFILLED":
      return {
        ...state,
        fetching: false,
        fetched: true,
        payload: action.payload
      };
    case "FETCH_BRANDS_PENDING":
      return {
        ...state,
        fetching: true,
        fetched: false
      };
    case "FETCH_EXAMPLES_FULFILLED":
      return {
        ...state,
        fetching: false,
        fetched: true,
        payload: action.payload,
        filteredSearch: false,
        newSearch: false,
        continuationMarker: action.payload.continuationMarker
          ? action.payload.continuationMarker
          : ""
      };
    case "FETCH_EXAMPLES_PENDING":
      return {
        ...state,
        fetching: true,
        fetched: false,
        newSearch: true
      };
    case "FETCH_FILTERED_EXAMPLES_FULFILLED":
      return {
        ...state,
        fetched: true,
        fetching: false,
        filteredSearch: true,
        payload: action.payload,
        continuationMarker: action.payload.continuationMarker
          ? action.payload.continuationMarker
          : ""
      };
    case "FETCH_FILTERED_EXAMPLES_PENDING":
      return {
        ...state,
        fetching: true,
        fetched: false
      };
    case "FETCH_CONTINUED_EXAMPLES_FULFILLED":
      return {
        ...state,
        fetched: true,
        fetching: false,
        filteredSearch: false,
        payload: action.payload,
        continuationMarker: action.payload.continuationMarker
          ? action.payload.continuationMarker
          : ""
      };
    case "FETCH_CONTINUED_EXAMPLES_PENDING":
      return {
        ...state,
        fetching: true,
        fetched: false
      };
    case "FETCH_CONTINUED_FILTERED_EXAMPLES_FULFILLED":
      return {
        ...state,
        fetched: true,
        fetching: false,
        filteredSearch: true,
        payload: action.payload,
        continuationMarker: action.payload.continuationMarker
          ? action.payload.continuationMarker
          : ""
      };
    case "FETCH_CONTINUED_FILTERED_EXAMPLES_PENDING":
      return {
        ...state,
        fetching: true,
        fetched: false
      };
    case "FETCH_OUTFIT_DETAILS_FULFILLED":
      return {
        ...state,
        details: action.payload,
        fetching: false,
        fetched: true
      };
    case "FETCH_OUTFIT_DETAILS_PENDING":
      return {
        ...state,
        fetching: true,
        fetched: false
      };
    default:
      return state;
  }
};

export default fetchReducer;
