import React from "react";
import { Link } from "react-router-dom";

interface outfitProps {
  image: string;
  recordName: string;
}

const Outfit: React.FC<outfitProps> = props => {
  return (
    <li>
      <Link to={"/outfits/" + props.recordName}>
        <img src={props.image} alt="" />
      </Link>
    </li>
  );
};

export default Outfit;
