import React from "react";

import {
  fetchOutfitExamples,
  fetchFilteredOutfits,
} from "../../Actions/fetchActions";

import { inspirationsProps, mapInterface, inspirationsState } from "./types";

import Outfit from "./Outfit";
import Filter from "./Filter";
import {
  genderFilter,
  seasonsFilter,
  styleFilter,
  patternFilter,
  colorFilter,
  itemFilter,
} from "./FilterList";

const { connect } = require("react-redux");

@connect((store: any) => {
  return {
    outfits: store.fetch.payload.records,
    filters: store.filters,
    fetch: store.fetch,
    filteredSearch: store.fetch.filteredSearch,
  };
})
export default class Inspirations extends React.Component<
  inspirationsProps,
  inspirationsState
> {
  constructor(props: any) {
    super(props);
    this.state = { outfits: [], newSearch: true, filters: props.filters };
  }

  componentDidMount() {
    let filterChecked = false;
    // check filters that are checked in the state
    let checkboxes = document.getElementsByClassName(
      "filter__checkbox"
    ) as HTMLCollectionOf<HTMLInputElement>;

    // find the filters that are saved in state
    for (let filter in this.state.filters) {
      if (this.state.filters[filter].length) {
        // then checked=true to the checkbox of the specific filter that we found in the state
        for (let i = 0; i < this.state.filters[filter].length; i++) {
          const filterName = this.state.filters[filter][i].replace(
            /^\w/,
            (c: string) => c.toUpperCase()
          );
          if (checkboxes.namedItem("filter__" + filterName)) {
            filterChecked = true;
            checkboxes.namedItem("filter__" + filterName)!.checked = true;
          }
        }
      }
    }

    if (filterChecked) {
      this.filterSearches();
    } else {
      this.props.dispatch(fetchOutfitExamples());
    }
  }

  componentDidUpdate() {
    // if new search and not loading more, clear the state and set flag to false until another new search
    if (
      this.state.newSearch &&
      this.state.outfits.length &&
      this.props.fetch.fetching
    ) {
      this.setState({ outfits: [], newSearch: true });
    }
  }

  componentWillReceiveProps(props: any) {
    let newSet = [new Set()];

    // check for outfits = undefined
    if (props.outfits) {
      newSet = [new Set([...this.state.outfits, ...props.outfits])];
    } else {
      newSet = [new Set()];
    }

    this.setState({
      outfits: Array.from(newSet[0]),
      filters: props.filters,
    });
  }

  //on click of 'Apply Filter'
  filterSearches = (e?: any, continuationMarker?: string) => {
    let filterArray: any[] = [];
    const filterToggler = document.getElementById(
      "filterToggle"
    ) as HTMLInputElement;

    //parse through the filters selected and create CloudKit 'filterTo' array
    for (let filterType in this.state.filters) {
      if (this.state.filters[filterType].length) {
        this.state.filters[filterType].map((selectedFilter: string) => {
          filterArray.push({
            fieldName: filterType,
            // comparator: "CONTAINS_ANY_TOKENS",
            comparator:
              filterType === "colors" ||
              filterType === "items" ||
              filterType === "patterns" ||
              typeof filterType === "object"
                ? "LIST_CONTAINS"
                : "EQUALS",
            fieldValue: {
              value: selectedFilter,
            },
          });
          return null;
        });
      } else {
        continue;
      }
    }

    if (e) {
      e.preventDefault();

      // if filter is clicked, then deemed new search and therefore state needs to be cleared
      // then dispath axios request to API and minimize the filter
      if (e.currentTarget.classList.contains("filter__apply")) {
        this.setState({ outfits: [], newSearch: true }, () => {
          this.props.dispatch(
            fetchFilteredOutfits(filterArray, continuationMarker)
          );
          filterToggler.checked = false;
        });
      } else if (e.currentTarget.classList.contains("continueSearch")) {
        this.setState({ newSearch: false }, () => {
          this.props.dispatch(
            fetchFilteredOutfits(filterArray, continuationMarker)
          );
        });
      }
    } else {
      this.props.dispatch(
        fetchFilteredOutfits(filterArray, continuationMarker)
      );
    }
  };

  // Do a continuted search when 'Load More' is pressed
  continueSearch = (e: any) => {
    this.setState({ newSearch: false });

    let isFilterSearch = this.props.filteredSearch;
    e.preventDefault();

    if (this.props.fetch.continuationMarker) {
      if (isFilterSearch) {
        this.filterSearches(e, this.props.fetch.continuationMarker);
      } else {
        this.props.dispatch(
          fetchOutfitExamples(this.props.fetch.continuationMarker)
        );
      }
    }
  };

  // clear the checkboxes in the filter
  clearFilters = () => {
    let checkboxes = document.getElementsByClassName(
      "filter__checkbox"
    ) as HTMLCollectionOf<HTMLInputElement>;

    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) {
        checkboxes[i].click();
      }
    }
  };

  closeFilter = () => {
    var filterToggler = document.getElementById(
      "filterToggle"
    ) as HTMLInputElement;
    filterToggler.checked = false;
  };

  public render() {
    // create outfits object queried from CloudKit API
    let outfits = this.state.outfits.length
      ? this.state.outfits.map((item: mapInterface) => {
          return (
            <Outfit
              image={item.fields.image.value.downloadURL}
              key={item.recordName}
              recordName={item.fields.name.value.replace(/small/g, "large")}
            />
          );
        })
      : null;

    return (
      <div id="inspirations" className="wrapper">
        <div className="title">
          <h2>Outfit Inspirations</h2>
        </div>

        <div className="feedback">
          <h5 title="Click to give feedback">
            <a href="https://lookscope.typeform.com/to/VEK3Ct">Give Feedback</a>
          </h5>
        </div>

        <div className="filterToggle">
          <input type="checkbox" id="filterToggle" />
          <label className="filterButton" htmlFor="filterToggle">
            Filter By <span className="filterButton__arrow"></span>
          </label>
          {/* <div className="searchBox">
            <input type="text" className="filterText" />
            <span>&#8250;</span>
          </div> */}
          <div className="filters">
            <div className="controls">
              <div className="clear" onClick={this.clearFilters}>
                Clear
              </div>
              <div className="close" onClick={this.closeFilter}>
                <span className="forwardSlash"></span>
                <span className="backslash"></span>
              </div>
            </div>
            <div className="filter__expanded filter__first">
              <Filter
                filters={genderFilter}
                filterTitle="Gender"
                filterType="radio"
                dispatch={this.props.dispatch}
              />
              <Filter
                filters={seasonsFilter}
                filterTitle="Seasons"
                filterType="radio"
                dispatch={this.props.dispatch}
              />
            </div>

            <div className="filter__expanded filter__second">
              <Filter
                filters={styleFilter}
                filterTitle="Style"
                filterType="radio"
                dispatch={this.props.dispatch}
              />
              <Filter
                filters={patternFilter}
                filterTitle="Pattern"
                filterType="checkbox"
                dispatch={this.props.dispatch}
              />
            </div>

            <div className="filter__expanded filter__third">
              <Filter
                filters={colorFilter}
                filterTitle="Color"
                filterType="color"
                dispatch={this.props.dispatch}
              />
            </div>

            <div className="filter__expanded filter__fourth">
              <Filter
                filters={itemFilter}
                filterTitle="Item"
                filterType="checkbox"
                dispatch={this.props.dispatch}
              />
            </div>
            <button className="filter__apply" onClick={this.filterSearches}>
              Apply Filters
            </button>
            <button
              className="filter__apply mobile"
              onClick={this.filterSearches}
            >
              Apply Filters
            </button>
          </div>
        </div>

        {outfits ? (
          <ul className="outfits">{outfits}</ul>
        ) : this.props.fetch.fetching ? (
          <p className="noResults">Loading Outfits...</p>
        ) : (
          <p className="noResults">No results found.</p>
        )}

        {this.props.fetch.fetching && !this.state.newSearch ? (
          <p className="noResults">Loading Outfits...</p>
        ) : null}
        {this.props.fetch.continuationMarker ? (
          <button className="continueSearch" onClick={this.continueSearch}>
            Load More
          </button>
        ) : null}
      </div>
    );
  }
}
