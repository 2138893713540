import React from "react";
import { Link } from "react-router-dom";

interface Event {
  target: {};
}

export default class Header extends React.Component {
  private appNode: React.RefObject<HTMLDivElement>;
  constructor(props: {}) {
    super(props);
    this.appNode = React.createRef();
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = (e: any): void => {
    const hamburgerToggle = document.getElementById(
      "hamburger"
    ) as HTMLInputElement;

    if (this.appNode.current && !this.appNode.current.contains(e.target)) {
      hamburgerToggle.checked = false;
    } else if (
      e.target.attributes.href ||
      e.target.parentElement.attributes.href
    ) {
      hamburgerToggle.checked = false;
    }
  };

  componentWillMount() {
    document.addEventListener("mousedown", this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick);
  }

  render() {
    return (
      <header className="navigationHeader" ref={this.appNode}>
        <div className="logo">
          <Link to="/">
            <img src=".\assets\images\Lookscope-word-logo.png" alt="" />
          </Link>
        </div>
        <nav>
          <input id="hamburger" type="checkbox" name="hamburger" />
          <label className="hamburger" htmlFor="hamburger">
            <span className="hamburger__top" />
            <span className="hamburger__middle" />
            <span className="hamburger__bottom" />
          </label>
          <div className="links">
            <div className="appButton">
              <a
                href="https://itunes.apple.com/app/apple-store/id1214741326?mt=8"
                className="button"
              >
                <button>Get the App</button>
              </a>
            </div>
            <div className="inspirationButton">
              <Link onClick={this.handleClick} to="/inspirations">
                See Inspirations
              </Link>
            </div>
            <Link to="/" onClick={this.handleClick} className="mobileLogo">
              <img src=".\assets\images\Lookscope-footer-logo.png" alt="" />
            </Link>
          </div>
        </nav>
      </header>
    );
  }
}
