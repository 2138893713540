import React from "react";

import { Link } from "react-router-dom";

import { fetchOutfitDetails } from "../../Actions/fetchActions";
import OutfitCategory from "./OutfitCategory";
import { outfitsProps, outfitsState } from "./types";

import { ReactComponent as BackButton } from "../../assets/images/back-button.svg";

const { connect } = require("react-redux");

@connect((store: any) => {
  return {
    details: store.fetch.details.records,
    fetched: store.fetch.fetched,
  };
})
export default class Outfits extends React.Component<
  outfitsProps,
  outfitsState
> {
  private appNode: React.RefObject<HTMLDivElement>;
  constructor(props: any) {
    super(props);
    this.appNode = React.createRef();
    this.copyToClipboard = this.copyToClipboard.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(fetchOutfitDetails(this.props.match.params.recordName));
    window.scrollTo(0, 0);
  }

  componentWillMount() {
    document.addEventListener("mousedown", this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick);
  }

  handleClick = (e: any) => {
    if (this.appNode.current && !this.appNode.current.contains(e.target)) {
      this.setState({ showModal: false });
    }
  };

  toTitleCase(str: string) {
    let txt = str.replace(/([A-Z])/g, " $1");
    return txt.charAt(0).toUpperCase() + txt.slice(1);
  }

  copyToClipboard(e: any) {
    e.preventDefault();
    e.currentTarget.innerText = "Link Copied";
    e.currentTarget.className = "clicked";

    const inputToCopy = document.getElementById(
      "modalUrlText"
    ) as HTMLInputElement;

    inputToCopy.select();
    inputToCopy.setSelectionRange(0, 99999);

    document.execCommand("copy");
  }

  render() {
    let outfitDetails = this.props.details,
      categories = [],
      month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
    if (outfitDetails && outfitDetails.length && this.props.fetched) {
      let text = outfitDetails[0].fields.outfitTitle
        ? outfitDetails[0].fields.outfitTitle.value.split(" ")
        : "";
      const textElements =
        text === ""
          ? null
          : text.map((word: string, i: number) => {
              return <span key={i}>{word}</span>;
            });
      const date = new Date(outfitDetails[0].modified.timestamp),
        descriptionOrder = [
          "headDescription",
          "topOuterDescription",
          "topMidDescription",
          "topInnerDescription",
          "topBottomDescription",
          "bottomDescription",
          "feetDescription",
          "otherDescription",
        ];

      // only show categories that exist in the API response
      for (let field in outfitDetails[0].fields) {
        if (field.includes("Description")) {
          categories.push(
            <OutfitCategory
              category={this.toTitleCase(field.replace(/Description/g, ""))}
              text={outfitDetails[0].fields[field].value}
              key={field}
            />
          );
        }
      }
      //sort the categories array
      for (let i = 0; i < categories.length; i++) {
        let category = categories[i].key as string;
        if (descriptionOrder.indexOf(category) !== i) {
          categories.splice(
            descriptionOrder.indexOf(category),
            0,
            categories.splice(i, 1)[0]
          );
        }
      }

      return (
        <main id="details">
          <section className="title">
            <div className="wrapper">
              <div className="back">
                <Link to="/inspirations" title="Back to Home Page">
                  <BackButton />
                </Link>
              </div>
              <h4>
                <span>
                  {outfitDetails[0].fields.outfitNumber
                    ? "Outfit No." + outfitDetails[0].fields.outfitNumber.value
                    : ""}
                </span>
                {text[0] ? (
                  <span className="subtitle">{textElements}</span>
                ) : null}
              </h4>
              <h5 className="when">
                {outfitDetails[0].fields.year.value +
                  " " +
                  outfitDetails[0].fields.season.value +
                  ": Week " +
                  outfitDetails[0].fields.week.value}
              </h5>
              {outfitDetails[0].fields.location ? (
                <h5 className="location">
                  {outfitDetails[0].fields.location.value}
                </h5>
              ) : (
                ""
              )}
              <p className="lastUpdate">
                Last Update:{" "}
                {month[date.getMonth()] +
                  " " +
                  date.getDate() +
                  ", " +
                  date.getFullYear()}
              </p>
            </div>
          </section>
          <section className="details">
            <div className="wrapper">
              {outfitDetails[0].fields.quote ? (
                <div className="quotes__blocks">
                  <blockquote>
                    <span>&ldquo;</span>
                    {outfitDetails[0].fields.quote.value}
                    <span>&rdquo;</span>
                    <div className="blockquote--author">
                      &#x23AF;
                      <span className="blockquote--author__name">
                        {outfitDetails[0].fields.quoteBy.value}
                      </span>
                    </div>
                  </blockquote>
                </div>
              ) : null}
              <div className="details__view">
                <div className="details__view--photo">
                  <img
                    src={outfitDetails[0].fields.image.value.downloadURL}
                    alt=""
                  />
                </div>
                <div className="details__view--categories">
                  {categories}
                  <button onClick={this.copyToClipboard}>
                    Share This Post
                  </button>
                  <input
                    id="modalUrlText"
                    readOnly
                    type="text"
                    value={document.URL}
                  />
                </div>
              </div>
            </div>
          </section>
        </main>
      );
    } else {
      return (
        <main id="details" className="loading">
          Loading...
        </main>
      );
    }
  }
}
