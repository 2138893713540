import React from "react";
import { Link } from "react-router-dom";

const Home: React.FC = () => {
  return (
    <main id="home">
      <div className="hero">
        <div className="hero__box">
          <h1>Build closet, see outfits.</h1>
          <a href="https://itunes.apple.com/app/apple-store/id1214741326?mt=8">
            <button>Download now for free</button>
          </a>
        </div>
      </div>
      <section className="quotes wrapper">
        <h3>What our users say</h3>
        <div className="quotes__blocks">
          <blockquote>
            &ldquo;I've been using Lookscope for one year now and I have to say
            it's changed how I see my closet and fashion … Using this app has
            helped me to clear out the clothes I don't wear. No longer do I have
            pieces of clothing that are hidden in my closet. Whether your closet
            is minimal or large, this app will help you make the most of what
            you have.&rdquo;
            <div className="rating">
              <p className="star">&#x2605;</p>
              <p className="star">&#x2605;</p>
              <p className="star">&#x2605;</p>
              <p className="star">&#x2605;</p>
              <p className="star">&#x2605;</p>
            </div>
            <div className="blockquote--author">
              <span className="blockquote--author__name">David (48)</span>,
              <span className="blockquote--author__local"> SF Bay Area</span>
            </div>
          </blockquote>
          <blockquote>
            &ldquo;Lookscope has helped me get out of my ‘style rut’ by showing
            me tons of new clothing combinations in my closet. I’ve also saved
            money on purchasing clothes (for a while at least) because I’m busy
            wearing these new combinations. I’d highly recommend this app to
            anyone looking to get organized and save time & money!&rdquo;
            <div className="rating">
              <p className="star">&#x2605;</p>
              <p className="star">&#x2605;</p>
              <p className="star">&#x2605;</p>
              <p className="star">&#x2605;</p>
              <p className="star">&#x2605;</p>
            </div>
            <div className="blockquote--author">
              <span className="blockquote--author__name">Dean (19)</span>,
              <span className="blockquote--author__local"> Houston</span>
            </div>
          </blockquote>
        </div>
      </section>
      <section className="examples">
        <div className="wrapper">
          <div className="examples--title">
            <h2>Get ideas and improve your style.</h2>
            <Link to="/inspirations">
              <button>Find outfit examples</button>
            </Link>
          </div>
          <ul className="examples--grid">
            <li>
              <img src=".\assets\images\collage\example-outfit-1.jpg" alt="" />
            </li>
            <li>
              <img src=".\assets\images\collage\example-outfit-2.jpg" alt="" />
            </li>
            <li>
              <img src=".\assets\images\collage\example-outfit-3.jpg" alt="" />
            </li>
            <li>
              <img src=".\assets\images\collage\example-outfit-4.jpg" alt="" />
            </li>
            <li>
              <img src=".\assets\images\collage\example-outfit-5.jpg" alt="" />
            </li>
            <li>
              <img src=".\assets\images\collage\example-outfit-6.jpg" alt="" />
            </li>
            <li>
              <img src=".\assets\images\collage\example-outfit-7.jpg" alt="" />
            </li>
            <li>
              <img src=".\assets\images\collage\example-outfit-8.jpg" alt="" />
            </li>
            <li>
              <img src=".\assets\images\collage\example-outfit-9.jpg" alt="" />
            </li>
            <li>
              <img src=".\assets\images\collage\example-outfit-10.jpg" alt="" />
            </li>
            <li>
              <img src=".\assets\images\collage\example-outfit-11.jpg" alt="" />
            </li>
            <li>
              <img src=".\assets\images\collage\example-outfit-12.jpg" alt="" />
            </li>
            <li>
              <img src=".\assets\images\collage\example-outfit-13.jpg" alt="" />
            </li>
            <li>
              <img src=".\assets\images\collage\example-outfit-14.jpg" alt="" />
            </li>
            <li>
              <img src=".\assets\images\collage\example-outfit-15.jpg" alt="" />
            </li>
            <li>
              <img src=".\assets\images\collage\example-outfit-16.jpg" alt="" />
            </li>
          </ul>
        </div>
      </section>
    </main>
  );
};

export default Home;
